import Decimal from '@st/decimal'
import type { Rolling } from '../types'

interface UseBonusRollingReturn {
  setCurrentRolling: () => Promise<void>
  isCurrentRollingLoading: Ref<boolean>
  isRollingStatusNewOrPaused: ComputedRef<boolean>
  isRollingStatusInProgress: ComputedRef<boolean>
  currentRollingAmount: ComputedRef<string>
  fullRollingAmount: ComputedRef<string>
  rollingStatus: ComputedRef<
    | 'new'
    | 'inProgress'
    | 'paused'
    | 'processed'
    | 'disqualified'
    | 'expired'
    | null
  >
  rollingProgress: ComputedRef<string>
}

export function useBonusRolling(
  rollingData: ComputedRef<Rolling | null>,
): UseBonusRollingReturn {
  const stFetch = useRawStFetch()
  const isCurrentRollingLoading = ref<boolean>(false)

  async function setCurrentRolling() {
    isCurrentRollingLoading.value = true
    const { error } = await stFetch('/user-bonus-rolling/set-current', {
      method: 'post',
      body: {
        userBonusRollingId: rollingData.value?.id || 0,
      },
    })
    isCurrentRollingLoading.value = false
    if (error) console.error(error)
  }
  const rollingStatus = computed(() =>
    rollingData.value ? rollingData.value.status : null,
  )
  const isRollingStatusNewOrPaused = computed(() =>
    ['new', 'paused'].includes(rollingData.value?.status ?? ''),
  )
  const isRollingStatusInProgress = computed(() =>
    rollingData.value ? rollingData.value.status === 'inProgress' : false,
  )

  const { format: formatCrypto } = useCryptoFormatter()

  const currentRollingAmount = computed(() =>
    formatCrypto(rollingData.value?.currentAmount ?? 0),
  )
  const fullRollingAmount = computed(() =>
    formatCrypto(rollingData.value?.fullAmount ?? 0),
  )
  const rollingProgress = computed(() => {
    if (!rollingData.value) return ''
    const result = new Decimal(rollingData.value.currentAmount)
      .dividedBy(rollingData.value.fullAmount)
      .mul(100)
      .toFixed(2)

    return Decimal.min(result, 100).toString()
  })

  return {
    setCurrentRolling,
    isCurrentRollingLoading,
    isRollingStatusNewOrPaused,
    isRollingStatusInProgress,
    currentRollingAmount,
    fullRollingAmount,
    rollingStatus,
    rollingProgress,
  }
}
